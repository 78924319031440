import Button from '@atoms/Button/Button';
import Modal from '@molecules/Modal/Modal';
import AlarmSVG from '@svg/alarm.svg';
import AwardSVG from '@svg/award.svg';
import HospitalSVG from '@svg/hospital.svg';
import LightSVG from '@svg/light.svg';
import VirusSVG from '@svg/virus.svg';
import WarningSVG from '@svg/warning.svg';
import { setCookie } from '@utils/clientCookies';
import getInternalUrl from '@utils/getInternalUrl';
import setExternalUrl from '@utils/setExternalUrl';
import PropTypes from 'prop-types';
import { useState } from 'react';

import styles from './BannerModal.module.scss';

const iconComponents = {
  alarm: AlarmSVG,
  warning: WarningSVG,
  light: LightSVG,
  virus: VirusSVG,
  hospital: HospitalSVG,
  award: AwardSVG,
};

function BannerModal({ banner }) {
  const [show, setShow] = useState(true);
  const Icon = iconComponents[banner.icon?.icon];
  let link = null;
  if (banner.internalLink[0]?.slug) {
    link = getInternalUrl(banner.internalLink[0]?.slug);
  } else if (banner.externalLink) {
    link = setExternalUrl(banner.externalLink);
  }

  const handleClose = () => {
    setCookie('hideBanner', banner.id, 365);
    setShow(false);
  };

  return (
    <Modal
      id={banner.id}
      show={show}
      onClose={() => handleClose()}
      modalModifier="banner"
    >
      <span className={styles.contentHeader}>
        {Icon && <Icon className={styles.icon} />}
        <h2 className={styles.title}>{banner.title}</h2>
      </span>
      <p className={styles.text}>{banner.text}</p>
      {link && (
        <Button
          href={link}
          color="white"
          label="Lees meer"
          onClick={handleClose}
          extraClasses={styles.button}
        />
      )}
    </Modal>
  );
}

BannerModal.propTypes = {
  banner: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    internalLink: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
      })
    ),
    externalLink: PropTypes.string,
    icon: PropTypes.shape({
      icon: PropTypes.string,
    }),
    display: PropTypes.bool,
  }),
};

export default BannerModal;
