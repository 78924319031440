import { bannerType } from '@lib/proptypes';

import BannerModal from './BannerModal';
import BannerTop from './BannerTop';

function Banner({ banner }) {
  if (!banner) return null;

  return (
    <>
      {banner.items.map(item =>
        item.displayNotAsModal ? (
          <BannerTop key={item.id} banner={item} />
        ) : (
          <BannerModal key={item.id} banner={item} />
        )
      )}
    </>
  );
}

Banner.propTypes = bannerType;

export default Banner;
