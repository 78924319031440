import gql from 'graphql-tag';

const BANNER_QUERY = gql`
  query BANNER_QUERY($locale: String) {
    BannerModals(sort: publish_on_DESC, locale: $locale, limit: 1) {
      items {
        typename: __typename
        id: _id
        title
        text
        internalLink: internal_link {
          ... on Model {
            slug: _slug
          }
        }
        externalLink: external_link
        icon {
          id: _id
          icon
        }
        displayNotAsModal: display
      }
    }
  }
`;

export default BANNER_QUERY;
