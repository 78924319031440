import PropTypes from 'prop-types';

export default PropTypes.shape({
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
      internalLibk: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.string,
        })
      ),
      externalLink: PropTypes.string,
      icon: PropTypes.shape({
        title: PropTypes.string,
      }),
      displayNotAsModal: PropTypes.bool,
    })
  ),
});
