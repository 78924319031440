import AlarmSVG from '@svg/alarm.svg';
import AwardSVG from '@svg/award.svg';
import CrossIcon from '@svg/cross.svg';
import HospitalSVG from '@svg/hospital.svg';
import LightSVG from '@svg/light.svg';
import VirusSVG from '@svg/virus.svg';
import WarningSVG from '@svg/warning.svg';
import { setCookie } from '@utils/clientCookies';
import getInternalUrl from '@utils/getInternalUrl';
import setExternalUrl from '@utils/setExternalUrl';
import classNames from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useState } from 'react';

import styles from './BannerTop.module.scss';

const iconComponents = {
  alarm: AlarmSVG,
  warning: WarningSVG,
  light: LightSVG,
  virus: VirusSVG,
  hospital: HospitalSVG,
  award: AwardSVG,
};

function BannerTop({ banner }) {
  const [show, setShow] = useState(true);
  const Icon = iconComponents[banner.icon.icon];
  let link = null;
  if (banner.internalLink[0]?.slug) {
    link = getInternalUrl(banner.internalLink[0]?.slug);
  } else if (banner.externalLink) {
    link = setExternalUrl(banner.externalLink);
  }

  const handleClose = () => {
    setCookie('hideBanner', banner.id, 365);
    setShow(false);
  };

  if (!show) {
    return null;
  }

  return (
    <div className={styles.banner}>
      <div className="u-container">
        <div className={styles.contentContainer}>
          <span className={styles.contentHeader}>
            {Icon && <Icon className={styles.icon} />}
            <h2 className={styles.title}>{banner.title}</h2>
            <p className={styles.text}>{banner.text}</p>
          </span>
          {link && (
            <Link
              href={link}
              className={classNames(styles.link, 'u-umbrella-link')}
              onClick={handleClose}
            >
              Lees meer
            </Link>
          )}
          <CrossIcon
            className={styles.closeIcon}
            onClick={handleClose}
            type="button"
          />
        </div>
      </div>
    </div>
  );
}

BannerTop.propTypes = {
  banner: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    internalLink: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
      })
    ),
    externalLink: PropTypes.string,
    icon: PropTypes.shape({
      icon: PropTypes.string,
    }),
    display: PropTypes.bool,
  }),
};

export default BannerTop;
