import Button from '@atoms/Button/Button';
import CloseButton from '@atoms/CloseButton/CloseButton';
import useFocusTrap from '@utils/useFocusTrap';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

import styles from './Modal.module.scss';

const DEFAULT_BODY_CLASS = 'modal-active';

export default function Modal({
  id,
  show,
  children,
  title,
  onClose,
  modifierClass,
  modalModifier,
  bodyClass,
  closeCtaLabel,
}) {
  const containerRef = useRef();
  const modalRef = useRef();
  const closeButtonRef = useRef();

  const { enableFocusTrap, disableFocusTrap } = useFocusTrap(
    modalRef,
    closeButtonRef
  );

  const handleClickOutside = e => {
    if (
      show &&
      !closeButtonRef?.current.contains(e.target) &&
      !modalRef?.current.contains(e.target)
    ) {
      onClose();
    }
  };

  useEffect(() => {
    /* Toggle body class */
    document.body.classList.toggle(bodyClass || DEFAULT_BODY_CLASS, show);

    /* Manage Focus trap */
    if (show) {
      enableFocusTrap();
    } else {
      disableFocusTrap();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  useEffect(() => {
    containerRef.current.addEventListener('click', handleClickOutside);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      containerRef.current?.removeEventListener('click', handleClickOutside);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <>
      <div
        ref={containerRef}
        id={id}
        className={classNames(
          styles.modalContainer,
          show && styles.show,
          styles[modifierClass]
        )}
      />
      <div
        className={classNames(styles.modal, styles[modalModifier])}
        ref={modalRef}
      >
        <header className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          <CloseButton
            onClick={onClose}
            label="Sluiten"
            controls={id}
            controlIsOpen={show}
            buttonRef={closeButtonRef}
            extraClasses={styles.closeButton}
          />
        </header>

        <div className={styles.content}>{children}</div>
        {closeCtaLabel && (
          <div className={styles.footer}>
            <Button label={closeCtaLabel} onClick={onClose} />
          </div>
        )}
      </div>
    </>
  );
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  modifierClass: PropTypes.string,
  modalModifier: PropTypes.string,
  bodyClass: PropTypes.string,
  closeCtaLabel: PropTypes.string,
};
