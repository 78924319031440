import axios from 'axios';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import getBase64 from './getBase64';
import getRandomInt from './getRandomInt';
import scrollTo from './scrollTo';
import useForm from './useForm';

export default function useApplicationForm({
  vacancyCode: initVacancyCode,
  aggregationChildren = [],
  aggLocationDetails = {},
  scrollToRef,
  applicationVacanciesRef,
}) {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { query, ...router } = useRouter();
  const [stage, setStage] = useState();
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [vacancyApplicationError, setVacancyApplicationError] = useState();
  const progressBarInterval = useRef();
  const { inputs, handleChange } = useForm({
    In: '', // initials
    CaNm: '', // firstname
    Is: '', // prefix
    LaNm: '', // lastname
    ViGe: '', // gender
    CoId: '', // country
    Ad: '', // street
    HmNr: '', // housenumber
    HmAd: '', // toevoeging
    ZpCd: '', // zipcode
    Rs: '', // city
    Mo: '', // motivation
    ViSo: '', // information source
    DaBi: '', // date of birth
    MbN2: '', // mobile phone
    EmA2: '', // work email
    U5F8BEDE64600DC34619ECCBBBF160222: '', // BIG registration
    U8E0273EE46440ED8292A7A966F37F5CC: '', // Hours per week
    U9FA557F74D947F024E0C6BB834579EC7: '', // Price
    UEE25D364416ABB14856253BE59291158: '', // Ben je op de speeddate geweest?
    OpEm: false, // Optin for data to be saved
    selectedVacancies: [],
  });

  /**
   * This section of code is responsible for handling the unique vacancies in the application form.
   * These are used to show in the application form as checkboxes along with location details.
   * It iterates through the `aggregationChildren` array and filters out duplicate vacancies based on location and hours.
   * The unique vacancies are stored in the `uniqueVacancies` array.
   */
  const vacancies = [];
  const vacancyMap = new Map();
  aggregationChildren.forEach(child => {
    const { locationCode, minHours, maxHours } = child;
    const locationDetails = aggLocationDetails[locationCode] || {};
    const key = `${locationCode}-${minHours}-${maxHours}`;

    if (!vacancyMap.has(key)) {
      const vacancy = {
        ...child,
        ...locationDetails,
        id: child.id,
        hasDetails: !!Object.keys(locationDetails).length,
        vacanciesCount: 1,
      };
      vacancies.push(vacancy);
      vacancyMap.set(key, vacancy);
    } else {
      const vacancy = vacancyMap.get(key);
      vacancy.vacanciesCount += 1;
      vacancy.vacancyCode = `${vacancy.vacancyCode},${child.vacancyCode}`;
    }
  });

  // Fake upload progress bar animation
  const startProgressBar = init => {
    let prev = init;
    progressBarInterval.current = setInterval(() => {
      prev = prev < 80 ? (prev += getRandomInt({ min: 1, max: 3 })) : prev;
      setProgress(prev);
    }, 1000);
  };

  const resetProgressBarInterval = () => {
    clearInterval(progressBarInterval.current);
  };

  const clearState = () => {
    setLoading(false);
    setStage();
    resetProgressBarInterval();
  };

  const handleSuccess = () => {
    // Clear the progress bar interval
    resetProgressBarInterval();
    setProgress(100);
    setStage('Klaar, u wordt doorgestuurd naar de bedanktpagina');
    // minor timeout so the user can read the message above
    setTimeout(() => {
      const href = query.id
        ? `/vacatures/${query.slug}/${query.id}/bedankt-voor-je-sollicitatie`
        : `/vacatures/${query.slug}/bedankt-voor-je-sollicitatie`;
      router.push(href);
    }, 1000);
  };

  // const handleError = ({ currentFailedApplications, errorMessage }) => {
  const handleError = error => {
    clearState();

    setError({
      message:
        error?.response?.data?.safeErrorMessage ||
        'Er is iets fout gegaan bij het versturen van je sollicitatie, probeer het later opnieuw.',
    });

    setProgress(0);
  };

  // Check if atleast 1 application vacancy is selected,
  // if that is not the case we should show an error
  const applicationVacanciesAreValid = () => {
    if (
      vacancies.length <= 1 ||
      (vacancies.length && inputs.selectedVacancies?.length > 0)
    )
      return true;

    setVacancyApplicationError(true);
    scrollTo({ scrollToRef: applicationVacanciesRef });
    return false;
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (loading) return;

    if (!applicationVacanciesAreValid()) {
      return;
    }
    // Check if the validation func exists
    if (!executeRecaptcha) {
      setError({
        message: 'Recaptcha kan niet worden geladen, probeer het opnieuw.',
      });
      return;
    }

    setLoading(true);
    scrollTo({ scrollToRef });
    setError();
    setVacancyApplicationError(false);

    // Start the first stage of loading the document.
    setStage('Cv verwerken');
    setProgress(10);

    let document;
    if (inputs.file) {
      document = await getBase64(inputs.file).catch(error => {
        console.error(error);
        setError({
          message:
            'Er is iets fout gegaan bij het laden van het bestand, probeer het opnieuw toe te voegen.',
        });
        clearState();
      });
    }
    // The stage for loading the document is done,
    // now starting the stage to post the application(s)
    setStage(
      `${
        inputs.selectedVacancies?.length > 1
          ? 'Sollicitaties versturen'
          : 'Sollicitatie versturen'
      }`
    );
    startProgressBar(25);

    const targetVacancies = inputs.selectedVacancies.length
      ? inputs.selectedVacancies
      : [initVacancyCode];
    const vacanciesQuery = targetVacancies
      .map(vacancyCode => `vacancy_codes[]=${vacancyCode}`)
      .join('&');

    // Post the application to the API
    await axios
      .post(
        `${process.env.SHARED_PREPR_API_ENDPOINT}/vacancy/apply?${vacanciesQuery}&email=${inputs.EmA2}`,
        {
          HrCreateApplicant: {
            Element: {
              Fields: {
                ...inputs,
                EmAd: inputs.EmA2, // Copy the private mail to workmail, this is required for afas
                CvFileStream: document?.base64String,
                CvFileName: document?.fileName,
              },
            },
          },
        }
      )
      .then(() => {
        handleSuccess();
      })
      .catch(error => {
        console.error(error);

        handleError(error);
      });
  };

  // Clean interval on unmount
  useEffect(() => () => clearInterval(progressBarInterval.current), []);

  return {
    inputs,
    handleChange,
    onSubmit,
    error,
    loading,
    stage,
    progress,
    vacancies,
    vacancyApplicationError,
  };
}
